
import { Order, OrderStatus } from '@/model/OrderCheckout'
import { formatDateTime, formatDurationFromNow, formatTime } from '@/formatter/DateFormatter'
import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { addOnBackgroundNotificationReceived, addOnNotificationReceived } from '@/notification/fcm.notification'
import { SendNotificationTokenService } from '@/service/SendNotificationTokenService'

export default class OrderDetailsView extends Vue {
  get order(): Order | null {
    return this.$store.state.currentOrder
  }

  get formattedOrderDate(): string | null {
    return formatDateTime(this.order.createdAt)
  }

  get formattedWaitingTime(): string | null {
    if (!this.order.business.displayEta || this.order.etaDate == null || this.order.etaDate < new Date()) {
      return null
    }
    return formatDurationFromNow(this.order.etaDate)
  }

  get formattedETATime(): string | null {
    if (!this.order.business.displayEta || this.order.etaDate == null) {
      return null
    }
    return formatTime(this.order.etaDate)
  }

  get isReceived(): boolean {
    return this.order?.status === OrderStatus.received
  }

  get isPickedUp(): boolean {
    return this.order?.status === OrderStatus.inProgress
  }

  get showPickedUpStep(): boolean {
    return this.isPickedUp || this.isReady
  }

  get isReady(): boolean {
    return this.order?.status === OrderStatus.readyToPickup
  }

  get isDone(): boolean {
    return this.order?.status === OrderStatus.done
  }

  get isCanceled(): boolean {
    return this.order?.status === OrderStatus.canceled || this.order?.status === OrderStatus.canceledByUser
  }

  mounted(): void {
    const route = useRoute()
    this.$store.dispatch('fetchOrder', route.params.id)
    addOnBackgroundNotificationReceived(() => {
      this.$store.dispatch('fetchOrder', route.params.id)
    })
    addOnNotificationReceived(() => {
      this.$store.dispatch('fetchOrder', route.params.id)
    })

    const sendNotificationTokenService = new SendNotificationTokenService()

    try {
      sendNotificationTokenService.sendNotificationToken()
    } catch (e) {
      console.log(e)
    }
  }
}

