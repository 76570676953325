import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CheckoutView from '../views/CheckoutView.vue'
import HomeView from '../views/HomeView.vue'
import OrderDetailsView from '../views/OrderDetailsView.vue'
import LoginView from '../views/LoginView.vue'
import SignupView from '../views/SignupView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import ConfirmResetPasswordView from '../views/ConfirmResetPasswordView.vue'
import AllOrdersView from '@/views/AllOrdersView.vue'
import { trackScreen } from '@/data/analytics'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:slug',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:slug/item/:item_id',
    name: 'menu_item',
    component: HomeView
  },
  {
    path: '/:slug/checkout',
    name: 'checkout',
    component: CheckoutView
  },
  {
    path: '/order/:id',
    name: 'order',
    component: OrderDetailsView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView
  },
  {
    path: '/reset-password/confirm/:u_id/:token',
    name: 'confirm-reset-password',
    component: ConfirmResetPasswordView
  },
  {
    path: '/:slug/orders',
    name: 'all-orders',
    component: AllOrdersView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, _from) => {
  if (to?.name) {
    trackScreen(to.name.toString(), to.name, { path: to.path })
  }
})

export default router
