import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-primary text-h1 font-weight-bold" }
const _hoisted_2 = { class: "text-h5" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "highlight" }
const _hoisted_5 = {
  class: "text-subtitle-1",
  align: "start"
}
const _hoisted_6 = {
  key: 0,
  align: "center",
  class: "mt-6"
}
const _hoisted_7 = {
  key: 1,
  align: "center",
  class: "mt-6"
}
const _hoisted_8 = {
  class: "me-4",
  width: "100%"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "me-4" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "me-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!
  const _component_v_timeline = _resolveComponent("v-timeline")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_ctx.order)
    ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            sm: 12,
            md: 8,
            "offset-md": 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "pa-4 align-self-left justify-left justify-start rounded-xl" }, {
                default: _withCtx(() => [
                  _createElementVNode("strong", _hoisted_1, "#" + _toDisplayString(_ctx.order.number), 1),
                  _createElementVNode("div", _hoisted_2, [
                    _createTextVNode(_toDisplayString(_ctx.$t('order_at_title')) + " ", 1),
                    _createVNode(_component_router_link, {
                      to: { name: 'home', params: { id: _ctx.order.business.id, slug: _ctx.order.business.slug } },
                      class: "link"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.order.business.title), 1)
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    (_ctx.order.table)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_v_chip, { color: "primary" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { icon: "mdi-table-furniture" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.order.table), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.formattedWaitingTime)
                    ? (_openBlock(), _createBlock(_component_v_card_text, {
                        key: 0,
                        class: "text-center mt-4",
                        innerHTML: _ctx.$t('estimated_waiting_time', { time: _ctx.formattedWaitingTime })
                      }, null, 8, ["innerHTML"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { class: "text-left text-subtitle-1 font-weight-regular m" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.cart, (item) => {
                            return (_openBlock(), _createBlock(_component_v_row, {
                              key: item.itemId,
                              class: "pt-1 pb-1 pl-0 pr-0"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { class: "pa-0 ma-0" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(item.count) + "x ", 1),
                                    _createElementVNode("span", null, _toDisplayString(item.title), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, { class: "pa-0 ma-0 text-right" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.totalPrice), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128)),
                          _createVNode(_component_v_divider, { class: "mt-8 mb-8" }),
                          _createVNode(_component_v_row, { class: "pb-1 pl-0 pr-0 text-h6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { class: "pa-0 ma-0" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('total')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { class: "pa-0 ma-0 text-right strong" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.order.totalPrice), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.order.comments)
                    ? (_openBlock(), _createBlock(_component_v_card_text, {
                        key: 1,
                        class: "text-left"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { icon: "mdi-comment-outline" }),
                          _createTextVNode(" " + _toDisplayString(_ctx.order.comments), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.isCanceled)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-cancel",
                            class: "mb-1",
                            color: "red",
                            size: "72"
                          }),
                          _createVNode(_component_v_card_text, { class: "text-center text-red text-subtitle-1 font-weight-regular" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("order_canceled_message")), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : (_ctx.isDone)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_v_icon, {
                              icon: "mdi-check-circle",
                              class: "mb-1",
                              color: "success",
                              size: "72"
                            }),
                            _createVNode(_component_v_card_text, { class: "text-center text-success text-subtitle-1 font-weight-regular" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('order_done_message')), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : (_openBlock(), _createBlock(_component_v_timeline, {
                            key: 2,
                            side: "end",
                            align: "start"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_timeline_item, {
                                "dot-color": "primary",
                                size: "small",
                                icon: "mdi-progress-clock",
                                class: _normalizeClass({ 'semi-transparent': !_ctx.isReceived })
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("strong", _hoisted_8, _toDisplayString(_ctx.$t('order_received_label')), 1),
                                  (_ctx.isReceived)
                                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('order_received_message')), 1))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.formattedOrderDate), 1)
                                ]),
                                _: 1
                              }, 8, ["class"]),
                              (_ctx.showPickedUpStep)
                                ? (_openBlock(), _createBlock(_component_v_timeline_item, {
                                    key: 0,
                                    "dot-color": "primary",
                                    size: "small",
                                    icon: "mdi-chef-hat",
                                    class: _normalizeClass({ 'semi-transparent': !_ctx.isPickedUp })
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("strong", _hoisted_10, _toDisplayString(_ctx.$t('order_picked_up_label')), 1),
                                      (_ctx.isPickedUp)
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('order_picked_up_message', { name: _ctx.order.business.title })) + " ", 1),
                                            (_ctx.formattedETATime)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                                  _createTextVNode(_toDisplayString(_ctx.$t('order_picked_up_eta_message')) + " ", 1),
                                                  _createElementVNode("strong", null, _toDisplayString(_ctx.formattedETATime), 1)
                                                ]))
                                              : _createCommentVNode("", true)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["class"]))
                                : _createCommentVNode("", true),
                              (_ctx.isReady)
                                ? (_openBlock(), _createBlock(_component_v_timeline_item, {
                                    key: 1,
                                    "dot-color": "primary",
                                    size: "small",
                                    icon: "mdi-check"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("strong", _hoisted_13, _toDisplayString(_ctx.$t('order_ready_label')), 1),
                                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('order_ready_message')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}