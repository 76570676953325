export default {
  "basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "cart_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir mon panier"])},
  "cart_empty_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide"])},
  "cart_go_to_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier ma commande"])},
  "menu_set_add_btn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajouter pour ", _interpolate(_named("price"))])},
  "item_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "section_menu_sets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
  "account_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
  "account_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe"])},
  "account_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "account_confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le nouveau mot de passe"])},
  "account_update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
  "account_passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
  "account_passwords_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été mis à jour"])},
  "account_reset_password_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email vous a été envoyé pour réinitialiser votre mot de passe."])},
  "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'enregister"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "account_no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore inscrit ?"])},
  "account_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà inscrit ?"])},
  "error_invalid_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champs est invalide"])},
  "error_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email invalide"])},
  "error_email_already_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse e-mail est déjà utilisée."])},
  "error_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'inscription."])},
  "error_field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
  "error_week_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe pas assez sécurisé"])},
  "error_invalid_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro de téléphone n'est pas valide"])},
  "error_field_too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est trop long"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "checkout_review_order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande chez ", _interpolate(_named("name"))])},
  "checkout_submit_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur: la commande n'est pas pu passer"])},
  "checkout_input_details_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations"])},
  "checkout_input_name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
  "checkout_input_phone_number_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre numéro de téléphone"])},
  "checkout_input_comment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire (optionnel)"])},
  "checkout_input_comment_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos commentaires"])},
  "checkout_establishment_address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de l'établissement"])},
  "checkout_establishment_address_itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire"])},
  "checkout_submit_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la commande"])},
  "order_count_ongoing_orders": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez ", _interpolate(_named("count")), " commandes en cours"])},
  "order_at_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande chez"])},
  "order_canceled_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette commande est annulée, à la prochaine fois pour une autre."])},
  "order_done_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette commande est terminée, à la prochaine fois pour une autre."])},
  "order_received_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande reçue"])},
  "order_received_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande devrait être préparée sous peu."])},
  "order_picked_up_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande en cours de préparation"])},
  "order_picked_up_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " est en train de traiter votre commande."])},
  "order_picked_up_eta_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elle devrait être prête aux alentours de "])},
  "order_ready_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prête"])},
  "order_ready_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande vous attend pour être récupérée."])},
  "order_status_received": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande <strong>#", _interpolate(_named("order_number")), "</strong> chez ", _interpolate(_named("business")), " est reçue."])},
  "order_status_in_progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande <strong>#", _interpolate(_named("order_number")), "</strong> chez ", _interpolate(_named("business")), " est en cours."])},
  "order_status_ready": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande <strong>#", _interpolate(_named("order_number")), "</strong> chez ", _interpolate(_named("business")), " est prête."])},
  "order_status_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande <strong>#", _interpolate(_named("order_number")), "</strong> chez ", _interpolate(_named("business")), "."])},
  "my_oders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
  "estimated_waiting_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps d'attente estimé: <strong>", _interpolate(_named("time")), "</strong>"])},
  "closed_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous somme fermés en ce moment"])},
  "error_invalid_credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email ou le mot de passe est incorrect."])},
  "you_are_authentifed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes identifié"])},
  "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups ! Quelque chose s'est mal passé"])},
  "try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])}
}